import React, { useState, useEffect } from 'react';
import Title from '../components/Title';
import SearchingBar from '../components/SearchingBar';
import SearchingLocationLabel from '../components/SearchingLocationLabel';
import WeatherDay from '../components/WeatherDay';
import Loading from '../components/Loading';


import './mainPage.css';

function MainPage() {

  const maxDayIndex = 2; // 3 days total

  const [location, setLocation] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [weatherData, setWeatherData] = useState([]);
  const [dayData, setDayData] = useState(null);
  const [datetime, setDatetime] = useState(null);

  const [dayIndex, setDayIndex] = useState(0);
  const [dayLabel, setDayLabel] = useState("Today");

  const backendUrl = process.env.REACT_APP_BACKEND_URL || "https://weathcastserver.onrender.com";

  function increaseDayIndex() {
    if (dayIndex < maxDayIndex) {
      setDayIndex(dayIndex + 1);
    }
  }

  function decreaseDayIndex() {
    if (dayIndex > 0) {
      setDayIndex(dayIndex - 1);
    }
  }

  useEffect(() => {
    switch (dayIndex) {
      case 0:
        setDayLabel("Today");
        break;
      case 1:
        setDayLabel("Tomorrow");
        break;
      default:
        setDayLabel(`In ${dayIndex} days`);
        break;
    }
  }, [dayIndex]);

  useEffect(() => {
    if (latitude === null || longitude === null) return;

    fetch(`${backendUrl}/api/v1/location?lat=${latitude}&lng=${longitude}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        setWeatherData(data.data);
        setDatetime(data.data.datetime);
      })
      .catch((error) => {
        alert(error);
      });
  }, [latitude, longitude]);

  useEffect(() => {
    if (weatherData.length === 0){
      setDayData(null);
      return;
    }
    setDayData(weatherData[dayIndex]);
  }, [dayIndex, weatherData]);


  
  return (
    <div
      className="container-fluid p-2"
    >
      <div
        className="container-fluid"
        style={{ minHeight: '100px' }}  
      >
        <Title />
        <SearchingBar
          setLocation={setLocation}
          setLongitude={setLongitude}
          setLatitude={setLatitude}
          setDayData={setDayData}
          setDayIndex={setDayIndex}
        />
        {location !== null && (
          <SearchingLocationLabel location={location} />
        )}
      </div>

      {(dayData === null && location !== null) && (
        <div
          className="container-xxl d-flex justify-content-center align-items-center"
          style={{ height: '50svh' }}
        >
          <Loading type={"bars"}/>
        </div>
      )}

      {dayData !== null && (
        <div className="container-xxl">
          <div className="row d-flex justify-content-center align-items-center mb-3">
            <div className="col-lg-1 col-3 d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary m-3"
                onClick={decreaseDayIndex}
              >
                <i className="bi bi-caret-left"></i>
              </button>
            </div>

            <div className="col-lg-3 col-6 d-flex justify-content-center">
              <h1 className="display-4">{dayLabel}</h1>
            </div>

            <div className="col-lg-1 col-3 d-flex justify-content-center">
              <button
                className=" btn btn-outline-secondary m-3"
                onClick={increaseDayIndex}
              >
                <i className="bi bi-caret-right"></i>
              </button>
            </div>

          </div>
          <div className="row d-flex justify-content-center">

            <WeatherDay
              date={dayData.daySummary.date}
              max_temp={dayData.daySummary.temp.max}
              min_temp={dayData.daySummary.temp.min}
              avg_temp={dayData.daySummary.temp.avg}
              avg_humidity={dayData.daySummary.general_data.avg_humidity}
              max_wind={dayData.daySummary.general_data.max_wind}
              total_precip_mm={dayData.daySummary.general_data.total_precip_mm}
              condition_label={dayData.daySummary.condition.label}
              condition_icon={dayData.daySummary.condition.icon}
              hourData={dayData.hourData}
              datetime={datetime}
            />
          </div> 
        </div>
      )}

    </div>
  );
}

export default MainPage;