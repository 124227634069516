import React from 'react';
import ReactLoading from 'react-loading';

function Loading({ type }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div>
        <h1
          className="display-6"
          style={{ fontSize: '2rem' }}
          >
            Loading...
        </h1>
      </div>
      <ReactLoading className="d-flex justify-content-center" type={type} color={"lightblue"} height={40} width={50} />
    </div>
  );
};

export default Loading;
