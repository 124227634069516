import React from 'react';

import DaySummary from './DaySummary';
import WeatherHour from './WeatherHour';



function WeatherDay({ ...props }) {

  return (
    <>
      <div className="col-12 col-lg-4">
        <DaySummary
          condition_icon={props.condition_icon}
          condition_label={props.condition_label}
          avg_temp={props.avg_temp}
          min_temp={props.min_temp}
          max_temp={props.max_temp}
          total_precip_mm={props.total_precip_mm}
          avg_humidity={props.avg_humidity}
          max_wind={props.max_wind}
        />
      </div>

      {/* DAY HOUR BY HOUR */}
      <div className="col-12 col-lg-8">
        <div className="container-fluid">
          <div
            className="card bg-light"
            style={{ boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="accordion" id="accordionExample">
              {props.hourData.map((hour, index) => (
                <React.Fragment key={index}>
                  {props.datetime < hour.time && (
                    <WeatherHour
                      id={index}
                      time={hour.time}
                      temp={hour.temp}
                      humidity={hour.humidity}
                      wind={hour.wind}
                      wind_dir={hour.wind_dir}
                      precip_mm={hour.precip_mm}
                      feelslike={hour.feelslike}
                      condition_label={hour.condition.label}
                      condition_icon={hour.condition.icon}
                      isLight={index % 2 === 0}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default WeatherDay;
