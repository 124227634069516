import React from 'react';



function SearchingLocationLabel({ ...props }) {

  return (
    <div className="d-flex justify-content-center">
      <p>Results found for the location: <b>{props.location}</b></p>
    </div>
  );
}

export default SearchingLocationLabel;