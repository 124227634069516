import React from 'react';



const imagePath = require('../assets/images/title.png');

function Title({ ...props }) {

  
  return (
    <div
      className="d-flex justify-content-center align-items-center"
    >
      <img
        className="img-fluid"
        style={{ maxHeight: '12vh' }}
        src={imagePath}
        alt="Service Title" />
    </div>
  );
}

export default Title;