import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';



import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import MainPage from "./pages/MainPage";

function App() {
  return (
    <Router>
      <div className="main">
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
