function DaySummary({ ...props }) {

  function handleMouseOver(event) {
    const img = event.currentTarget.querySelector('img');
    img.style.transform = 'scale(1.3)';
    img.style.transition = 'transform 0.3s ease';    
  }
  
  function handleMouseOut(event) {
    const img = event.currentTarget.querySelector('img');
    img.style.transform = 'scale(1)';
    img.style.transition = 'transform 0.3s ease'; // Aggiungi una transizione di 0.3 secondi con easing
  }

  return (

    <div className="m-3"> 

      <div
        className="card text-white bg-info"
        style={{ boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)' }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >

        <div className="card-header d-flex align-items-center p-2 ">
          <div
            className="container-fluid"
          >

            <div className="container-fluid ">
              <p className="m-0">DAILY SUMMARY</p>  
            </div>

            <div className="container-fluid p-0 d-flex align-items-center justify-content-start">
              <img
                className="m-2"
                src={props.condition_icon}
                alt="Weather Icon"
              />
              <p
                className="display-6 m-0 ms-2"
                style={{ fontSize: '2rem' }}
              >
                {props.condition_label}
              </p>
            </div>

          </div>
        </div>

        <div className="card-body">
          
          <div className="">
            <p
              className="display-6 m-0 p-0 mb-2"
              style={{ fontSize: '1.2rem' }}
            >
              Avg. Temperature: <b>{props.avg_temp}°C</b><br />(min: {props.min_temp}°C, max: {props.max_temp}°C)
            </p>
          </div>

          <div className="">
            <p
              className="display-6 m-0 p-0 mb-2"
              style={{ fontSize: '1.2rem' }}
            >
              Total Precipitation: <b>{props.total_precip_mm} mm</b>
            </p>
          </div>

          <div className="">
            <p
              className="display-6 m-0 p-0 mb-2"
              style={{ fontSize: '1.2rem' }}
            >
              Avg. Humidity: <b>{props.avg_humidity}</b>
            </p>
          </div>

          <div className="">
            <p
              className="display-6 m-0 p-0 mb-2"
              style={{ fontSize: '1.2rem' }}
            >
              Max Wind Speed: <b>{props.max_wind} km2</b>
              </p>
          </div>
          
        </div>

      </div>
    </div>

  )
}

export default DaySummary;
