import React, { useState } from 'react';

function WeatherHour({ ...props }) {

  return (
    <div>

      <div
        className={`container-fluid border ${props.isLight ? 'bg-light' : 'bg-white'} p-0`}
        style={{ minHeight: '8vh', height: 'fit-content',  }}
      >
        <div className="row d-flex align-items-center m-0 p-0">
          <div className="col-3 d-flex justify-content-center">
            <h5 className="m-0">{(props.time).split(" ")[1]}</h5>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <img
              className="p-1"
              src={props.condition_icon}
              alt="Weather Icon"
              style={{ "height": "7vh" }}
            />
          </div>
          <div className="col-4 d-flex justify-content-start">
            <h5 className="font-weight-normal m-0">{props.condition_label}</h5>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <h6 className="m-0">{props.temp}°C</h6>
          </div>
        </div>
      </div>

{/* 
      <div id={collapseID} className={`accordion-collapse collapse ${isCollapsed ? '' : 'show'}`} aria-labelledby={headingID} data-bs-parent={`#${accordionID}`}>
        <div className="accordion-body">
          <h6>Precipitation: {props.precip_mm} (mm)</h6>
          <h6>Wind: {props.wind}km/h ({props.wind_dir})</h6>
          <h6>Humidity: {props.humidity}%</h6>
        </div>
      </div> */}

    </div>
  );
}

export default WeatherHour;
