import React, { useState } from 'react';

function SearchingBar({ ...props }) {

  const [searchQuery, setSearchQuery] = useState('');

  async function convert_in_coordinates(address) {
    if (address) {
      const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        address
      )}&format=json&limit=1&addressdetails=1`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.length > 0) {
          const { lat, lon } = data[0];
          props.setLocation(data[0].display_name);
          props.setLongitude(lon);
          props.setLatitude(lat);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }


  async function searchLocation(placeLabel) {
    await convert_in_coordinates(placeLabel);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  }

  function handleSearch(e) {
    props.setDayData(null);
    e.preventDefault();
    setSearchQuery('');
    props.setDayIndex(0);
    searchLocation(searchQuery);
  }

  

  return (
    <div className="row d-flex align-items-center justify-content-center">
      <div className="d-flex justify-content-center col-9 col-sm-10 col-md-6">
        <input
          type="text"
          style={{ width: '100%' }}
          className="ms-0"
          // className="form-control"
          placeholder="Insert a location..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          type="button"
          className="btn btn-secondary ms-2"
          onClick={handleSearch}
        >
          <i className="bi bi-search" />
        </button>
      </div>

    </div>
  );
}

export default SearchingBar;
